<script>
import accessoriesService from '@/services/accessories.service'
import brandsService from '@/services/brands.service'
import lazyType from '@/utils/lazy-type'

export default {
  name: 'Accessories',
	data() {
		return {
      items: [],
      limit: 20,
      offset: 1,
      totalItems: 0,
      activeCategoryTitle: this.$t('allCategories'),
      categories: [],
      selectedCategory: null,
      activeBrandTitle: this.$t('allBrands'),
      brands: [],
      selectedBrand: null,
      keyword: "",
      lazyTypeDelay: 200,
      headerFields: [
        "__slot:checkboxes",
        {
          name: "published",
          label: this.$t('published'),
          customElement: "published"
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo"
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title"
        },
        {
          name: "category",
          label: this.$tc('category'),
          format: val => val['name']
        },
        {
          name: "brand",
          label: this.$tc('brand'),
          format: brands => brands.map(brand => brand.name).join(', ')
        },
        {
          name: "changed_at",
          label: this.$t('lastUpdated'),
          format: date => new Date(date).toLocaleString('en-GB')
        },
        "__slot:actions:edit",
        "__slot:actions:delete"
      ],
		}
  },
	mounted() {
    this._get()
    this._getCategories()
    this._getBrands()
	},
	methods: {
    lazyType,
    _get(resetOffset = true) {
      if(resetOffset) this.offset = 1
			accessoriesService.getAll({ category: this.selectedCategory, brand: this.selectedBrand, limit: this.limit, offset: this.offset, keyword: this.keyword }).then(
				response => {
          this.items = response.data
          this.items.map(item => {
            item.changed_at = item.updated_at || item.created_at
            return item
          })
          this.totalItems = Number(response.headers['x-items-count']) || 0
				}
			)
    },
    _getCategories() {
      accessoriesService.getCategories()
        .then(res => {
          this.categories = res.data
        })
    },
    _getBrands() {
      brandsService.getAll({})
        .then(res => {
          this.brands = res.data
        })
    },
    filterByCategory(category) {
      this.activeCategoryTitle = category || this.$t('allCategories')
      this.selectedCategory = category
      this._get()
    },
    filterByBrand(brand) {
      this.activeBrandTitle = brand.name || this.$t('allBrands')
      this.selectedBrand = brand.alias
      this._get()
    },
    filterBySearch() {
      this._get()
    },
    updatePagination(offset) {
      this.offset = offset
      this._get(false)
    },
    remove(id) {
      accessoriesService.remove({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('accessory') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="form-group col-12 text-right">
              <router-link :to="{ name: 'singleAccessory', params: { id: 'new' } }">
                <BaseButton 
                  type="green"
                  gradient
                >
                  {{ $t('addNewItem', { item: $tc('accessory') }) }}
                </BaseButton>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-between align-items-end">
            <div class="col-md-3">
              <label class="control-label">{{ $t('filterBy', { item: $tc('category') }) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeCategoryTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByCategory('')"
                >{{ $t('allCategories') }}</a>
                <a
                  v-for="category of categories"
                  :key="category.id+'filter'+'category'"
                  class="dropdown-item"
                  @click="filterByCategory(category.alias)"
                >{{ category.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-3">
              <label class="control-label">{{ $t('filterBy', { item: $tc('brand') } ) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeBrandTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByBrand('')"
                >{{ $t('allBrands') }}</a>
                <a
                  v-for="brand of brands"
                  :key="brand.id+'filter'+'brand'"
                  class="dropdown-item"
                  @click="filterByBrand(brand)"
                >{{ brand.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-3">
              <BaseInput
                v-model="keyword"
                :placeholder="$t('search')"
                class="search"
                @keyup.prevent.stop="lazyType(filterBySearch, lazyTypeDelay)"
              />
            </div>
          </div>
        </div>
        <div class="card-body">
          <BaseListTable
            :items="items"
            :total-items="totalItems"
            :limit="limit"
            :offset="offset"
            :header-fields="headerFields"
            type="accessories"
            has-pagination
            @updatePagination="updatePagination"
            @delete="remove"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.search
  margin-bottom: $base-spacing / 4
</style>
